
.PropertyEngineRSVP{

    background: #f6f7f8;

    .section--banner{
        background: #fff;
        border-bottom: 1px solid rgba(46,62,72,.12);
    }
    .section--content{
        //background: green;
    }

    .banner {
        //margin: 2em 0;
        padding: 3em 0;

        .banner__date{
            margin-bottom: 0;
            font-size: 1em;
            color: #585858;
        }

        .banner__title{
            margin: 0;
            font-size: 2.5em;
            font-weight: bold;

        }
    }

    .main-content{
        padding: 3em 0;
    }

    .user-response{
        .ui.button{
            &.text-button{
                width:25%;
                max-width:calc(25% - 5px);
                box-shadow: none;
                line-height: 0.5em;
            }
            width:50%;
            max-width:calc(50% - 5px);

            .ico-icon{
                margin-right: 1em;
            }
        }
    }



    .flyer{
        .flyer__image{
            margin-bottom: 2em;
        }
        .flyer__description{}
    }

    .event-host{
        display:block;
    }
    .event-information{
        padding: 2em 2em 1em 2em!important;
        .item{
            margin-bottom: 1em;
        }

        .event-information__date{
            margin-bottom: 0.5em;
        }
        .event-information__calendar-button{

        }

    }
    .extra-address-info{
        margin-top: 0.5em;
        font-style: italic;
    }


}


.calendar-modal{
    width: 200px!important;

    .button{
        margin-bottom: 0.5em!important;
    }
}